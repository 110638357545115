<template>
  <div class="box">
    <div class="perBox">
      <baidu-map @ready="getCurrentCity">
        <div class="topLeft" @click="getCurrentCity">
          <div @click="showOverlay = true"></div>
          <div @click="showOverlay = true">{{currentCity}}</div>
        </div>
      </baidu-map>
      <div class="topCon">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="value1" :options="radioList" @change="onChange" />
        </van-dropdown-menu>
      </div>
      <div class="topRight"></div>
    </div>
    <div class="tabBox">
      <div class="tabDiv">
        <van-tabs v-model="tabActive" swipeable @click="tabEvent" @change="onTabChange()">
          <van-tab v-for="item in tabList" :title="item.value" :key="item.id">
            <!-- <lookAtChild :childId="item.id"></lookAtChild> -->
            <div class="container">
              <div class="content" v-for="item in lookList" :key="item.id">
                <div class="contentLeft">
                  <div class="contentTitle" @click="detailEvent(item)">{{item.title}}</div>
                  <div class="contentLeftFoote">
                    <div class="zd" v-if="item.top != 0">置顶</div>
                    <div class="contentName">{{item.createName}}</div>
                    <div class="contentTime">{{item.createTime}}</div>
                  </div>
                </div>
                <div class="contentRight">
                  <div class="contentRightImg">
                    <img :src="item.img" alt />
                  </div>
                  <div class="likeNum">{{item.likeNum}}点赞</div>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="tabImg" @click="tabShow = true"></div>
    </div>
    <!-- 获取当前位置弹框 -->
    <van-overlay :show="showOverlay">
      <div class="kuang">
        <div class="del">
          <van-icon name="close" size="20" @click="showOverlay = false" />
        </div>
        <div class="dwSize">定位显示您在{{currentCity}}</div>
        <div class="btn" @click="getCurrentCity">切换到{{currentCity}}</div>
        <div class="btn1" @click="showOverlay = false">继续浏览{{currentCity}}</div>
      </div>
    </van-overlay>
    <!-- 频道目录 -->
    <van-popup v-model="tabShow" position="bottom" round :style="{ height: '90%'}">
      <div class="delImgTabShow">
        <img @click="tabShow=false" src="../../image/delImg.png" alt />
      </div>
      <div class="tabShowTitle">
        <div>频道目录</div>
        <div>点击进入频道</div>
        <!-- <div>编辑</div> -->
      </div>
      <div class="tabShowList">
        <div
          v-for="(item,index) in tabList"
          :key="item.id"
          :class="tabShowActive === item.id ? 'tabShowactive' : ''"
          @click="tabShowEvent(item,index)"
        >{{item.value}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Overlay } from "vant";
import { DropdownMenu, DropdownItem } from "vant";
import { Popup } from "vant";
import { Cascader } from "vant";
import { Tab, Tabs } from "vant";
import { Swipe, SwipeItem } from "vant";
import { dictionary, infoPage } from "../../api/lookAt";
export default {
  data() {
    return {
      value1: "", //周边
      // option1: [
      //   { text: "周边-初中", value: 0 },
      //   { text: "周边-高中", value: 1 },
      //   { text: "周边-小学", value: 2 }
      // ],
      currentCity: "", //当前位置
      showOverlay: false, //当前地理位置弹框
      cascaderValue: "",
      options: [],
      radioList: [],
      gradActive: 0,
      tabList: [],
      tabActive: 0,
      tabShow: false, //频道目录弹框
      tabShowActive: "", //频道目录弹框中的列表状态
      lookData: {
        pageNum: 1,
        pageSize: 10,
        content: "", //搜索
        provinceId: "", //省级id
        cityId: "", //市级id
        period: "", //学段id
        classify: "" //tabId
      },
      lookList: [] //列表
    };
  },
  mounted() {
    this.lookData.classify = this.$store.state.tabList[0].key;
    // console.log(this.$store.state.optionsList, "====");
    // 分类
    dictionary({ parentId: 1 }).then(res => {
      // console.log(res, "tab分类");
      this.tabList = res.data.data;
    });
    // 学段
    dictionary({ parentId: 13 }).then(res => {
      console.log(res, "学段++++");
      this.value1 = res.data.data[0].id;
      this.lookData.period = res.data.data[0].key;
      this.radioList = res.data.data.map(item => {
        return {
          value: item.id,
          text: item.value,
          key: item.key
        };
      });
    });
    this.gerinfoPage();
  },
  methods: {
    gerinfoPage() {
      // this.lookData.classify = this.$store.state.tabList[0].id;
      // this.lookData.period = this.value1;
      infoPage(this.lookData).then(res => {
        console.log(res, "资讯列表");
        this.lookList = res.data.data.items;
      });
    },
    // 获取当前地理位置
    getCurrentCity({ BMap }) {
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(result => {
        if (geolocation.getStatus() === 0) {
          const city = result.address.city;
          this.currentCity = city;
          this.showOverlay = false;
          console.log(city);
          this.$store.state.optionsList.forEach(item => {
            item.children.forEach(i => {
              if (i.name === city) {
                console.log(i.id);
                this.lookData.provinceId = item.id;
                this.lookData.cityId = item.id;
                console.log(item.id);
                this.gerinfoPage();
              }
            });
          });
        } else {
          this.currentCity = "定位失败";
        }
      });
    },
    onChange(value) {
      // console.log(value,'------');
      // console.log(this.radioList, "++++");
      this.radioList.forEach(item => {
        if (item.value === value) {
          // console.log(item,'......');
          this.lookData.period = item.key;
          console.log(this.lookData.period, item.key);

          this.gerinfoPage();
        }
      });
    },
    // 导航点击事件
    tabEvent(item, index) {
      this.tabActive = item;
      this.tabList.forEach(i => {
        if (i.value === index) {
          this.lookData.classify = i.key;
          this.tabShowActive = i.id;
          this.gerinfoPage();
        }
      });
    },
    // 频道导航
    tabShowEvent(item, index) {
      console.log(item);
      this.lookData.classify = item.key;
      this.tabShowActive = item.id;
      this.tabActive = index;
      this.tabShow = false;
      this.gerinfoPage();
    },
    onTabChange() {
      console.log(this.tabActive);
      this.tabList.forEach((item, index) => {
        if (this.tabActive === index) {
          console.log(item);
          this.lookData.classify = item.key;
          this.gerinfoPage();
        }
      });
    },
    // 跳转详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { item: item } });
    }
  }
};
</script>

<style scoped>
.box {
  position: relative;
}
.perBox {
  width: 750px;
  height: 88px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topCon{
  margin-left: -50px;
}
.topLeft {
  display: flex;
  align-items: center;
}
.topLeft div:nth-child(1) {
  background-image: url("../../image/wz.png");
  width: 32px;
  height: 32px;
  background-size: 100% 100%;
  margin-left: 32px;
}
.topLeft div:nth-child(2) {
  font-size: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
}
.topRight {
  width: 48px;
  height: 48px;
  background-image: url("../../image/zbSear.png");
  background-size: 100% 100%;
  margin-right: 32px;
}
.kuang {
  width: 622px;
  height: 444px;
  background: #ffffff;
  border-radius: 32px 32px 32px 32px;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.del {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
}
.dwSize {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-top: 20px;
}
.btn {
  width: 526px;
  height: 90px;
  background: #0957c3;
  border-radius: 1354px 1354px 1354px 1354px;
  opacity: 1;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin: 0 auto;
  margin-top: 50px;
}
.btn1 {
  width: 526px;
  height: 90px;
  background: #f7f8fa;
  border-radius: 1090px 1090px 1090px 1090px;
  opacity: 1;
  font-size: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  line-height: 90px;
  margin: 0 auto;
  margin-top: 48px;
}
/* tab 列表 */
.tabBox {
  position: relative;
}
.tabDiv {
  width: 686px;
}
.container {
  margin-bottom: 120px;
}
.content {
  display: flex;
  width: 686px;
  margin-left: 32px;
  margin-top: 48px;
}
.contentTitle {
  width: 450px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.contentRightImg {
  width: 204px;
  height: 136px;
  opacity: 1;
  margin-left: 32px;
}
.contentRightImg img {
  width: 204px;
  height: 136px;
  border-radius: 8px 8px 8px 8px;
}
.contentLeftFoote {
  width: 450px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.contentName,
.contentTime {
  margin-left: 16px;
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.26);
}
.contentName {
  margin-left: 0px;
}
.likeNum {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.26);
  text-align: right;
  margin-top: 18px;
}
.zd {
  width: 64px;
  height: 44px;
  background: rgba(69, 103, 230, 0.1);
  font-size: 24px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  color: #0957c3;
  line-height: 44px;
  text-align: center;
  margin-right: 16px;
}
.tabImg {
  width: 32px;
  height: 32px;
  background-image: url("../../image/tabImg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 25px;
  right: 32px;
}
/deep/.van-tabs__nav {
  background-color: transparent;
}
/deep/.van-tab--active {
  color: rgba(0, 0, 0, 0.9) !important;
  font-size: 28px;
}
/deep/.van-tab {
  color: rgba(0, 0, 0, 0.4);
}
/deep/.van-tabs__line {
  background-color: transparent;
}
/* 频道弹框 */
.delImgTabShow img {
  width: 32px;
  height: 32px;
  margin-left: 32px;
}
.tabShowTitle {
  display: flex;
  align-items: center;
}
.tabShowTitle div:nth-child(1) {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  margin-left: 32px;
}
.tabShowTitle div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 32px;
}
.tabShowTitle div:nth-child(3) {
  font-size: 28px;
  font-weight: 500;
  color: #5777ed;
  margin-left: 326px;
}
.tabShowList {
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 48px;
  margin-left: 6px;
}
.tabShowList div {
  width: 152px;
  height: 74px;
  background: #f7f8fa;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-bottom: 26px;
  text-align: center;
  line-height: 74px;
  margin-left: 26px;
}
.tabShowactive {
  color: #5777ed;
}
</style>
